import React, { useEffect, useState } from "react";
import CIcon from "@coreui/icons-react";
import { freeSet } from '@coreui/icons';

interface TextInputInterface {
	onChange: (value: string) => void;
	defaultValue?: string;
	placeholder?: string;
	required?: boolean;
	error?: boolean;
	disabled?: boolean;
	type?: "text" | "email" | "password"
}

const TextInput: React.FC<TextInputInterface> = ({ onChange, defaultValue, placeholder, required, error, disabled, type }) => {
	const [value, setValue] = useState(defaultValue);
	const [hasError, setHasError] = useState(error);

	useEffect(() => {
		if (required) {
			setHasError(!value || !Boolean(value.length) || error);
		}
		else setHasError(error);
	}, [value, error]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		setValue(newValue);
		onChange(newValue);
	};

	const clearInputValue = () => {
		setValue("");
		onChange("");
	};

	const inputClasses = `border-solid border rounded-lg border-zinc-200 duration-300 ${hasError ? 'border-red-200' : 'hover:border-zinc-400'}`;
	const iconClasses = `mx-2 text-zinc-500 ${disabled ? 'cursor-default' : 'cursor-pointer'}`;

	return (
		<div className='w-full'>
			<div className={`${inputClasses} w-full flex items-center ${disabled ? "bg-zinc-50" : "bg-zinc-100"}`}>
				<input
					type={type}
					placeholder={placeholder}
					className={`w-full h-full rounded-l-lg outline-none px-4 py-1.5 ${disabled && 'cursor-not-allowed'}`}
					value={value}
					onChange={handleChange}
					disabled={disabled}
				/>
				{disabled ? <></> : (
					<>
						{value ?
							(
								<span className={iconClasses} onClick={!disabled ? clearInputValue : undefined}>
									<CIcon icon={freeSet.cilX} width={20} size="xxl" />
								</span>
							) : (
								<span className={iconClasses}>
									<CIcon icon={freeSet.cilPen} width={20} size="xxl" />
								</span>
							)
						}
					</>
				)}
			</div>
		</div>
	);
};

TextInput.defaultProps = {
	defaultValue: '',
	placeholder: 'Digite aqui...',
	required: false,
	error: false,
	disabled: false,
	type: 'text',
}

export default TextInput;
